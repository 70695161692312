<template>
  <div class="session-workflow themev2">
    <Modal2 @close="$emit('close')">
      <h3 slot="header">{{headerTitle}}</h3>

      <div slot="body" style="width: 336px;" v-if="page == 'initial'">        
        <label>Sesion ID</label>
        <span style="color:#777;">{{session.id}}</span>
        <br>
        <label>Experience</label>
        <span>{{session.experienceName ? session.experienceName : ( session.experience ? session.experience.name : 'MISSING' )}}</span>
        <br>
        <label>Sesion name</label>
        <span>{{session.name}}</span>
        <br>
        <label>Date &amp; Time</label>
        <span v-if="session.startDate && session.endDate">{{formatDates(new Date(session.startDate), new Date(session.endDate))}}</span>
        <span v-else>No dates</span>        
        <br>
        <label>Number of {{session.practice ? 'practice devices' : 'learners'}}</label>
        <span>{{session.userCount}}</span>
        <br>
        <label>Learner type</label>
        <span>{{formatProgramType(session.program)}}</span>
        <br>
        <label>Facilitator</label>
        <span v-if="facilitator">{{facilitator}}</span>
        <span v-else class="spinner"></span>
        <br>
        <label>Teaching assistants</label>
        <template v-for="(user, index) in teachingAssistants">
          <span :key="user.id">{{user}}</span>
          <br v-if="index + 1 < teachingAssistants.length" :key="user.id+'br'">
        </template>
        <span v-if="teachingAssistants && teachingAssistants.length == 0">-</span>
        <span v-if="!teachingAssistants" class="spinner"></span>
        <br>
        <label>Location</label>
        <span>{{session.location ? session.location : '-'}}</span>        
      </div>

      <div slot="body" style="width: 336px;" v-if="page == 'reject'">
        <label>Reject is sent to</label>
        <span v-if="facilitator">{{facilitator}}</span>
        <span v-else class="spinner"></span>
        <br>
        <label>Reject reason</label>
        <textarea style="height:160px;" v-model.trim="rejectReason" :disabled="loading"></textarea>
      </div>

      <div slot="footer" v-if="page == 'initial'"> 
        <span class="spinner" v-if="loading" style="margin-top:8px;"></span>       
        <button class="right" v-if="session.workflow == 4" @click="setWorkflow(3);" :disabled="loading">Mark not ready</button>
        <button class="right" v-if="session.workflow != 4" @click="setWorkflow(3);" :disabled="loading || ![0,1,2].includes(session.workflow)">Approve</button>
        <button class="right white" v-if="session.workflow != 4" @click="page = 'reject'" :disabled="loading || session.workflow == 1">Reject</button>        
      </div>
      <div slot="footer" v-else>   
        <span class="spinner" v-if="loading" style="margin-top:8px;"></span>        
        <button class="right" @click="setWorkflow(1);" :disabled="loading">Send</button>
        <button class="right white" @click="$emit('close')" :disabled="loading">Cancel</button>
      </div>
    </Modal2>
  </div>
</template>

<script>
import axios from 'axios'
import Modal2 from '@/components/Modal2.vue'
import { formatProgramType, formatWorkflowStatus, formatDates } from '@/utils.js'

export default {
  name: 'SessionWorkflow', 
  props: {
    session: Object,
  },
  components: {
    Modal2
  },
  data: function(){
    return{
      error: undefined,
      loading: false,
      page: 'initial',
      facilitator: null,
      teachingAssistants: null,
      rejectReason: null
    }
  },
  computed: {
    headerTitle(){
      return this.page == 'initial' 
        ? `${this.formatWorkflowStatus(this.session.workflow)} ${this.session.workflow == 0 ? 'session' : 'request'}`
        : 'Reject request'
    }    
  },
  methods: {
    formatDates(d1,d2){ return formatDates(d1,d2) },
    formatWorkflowStatus(status){
      return formatWorkflowStatus(status)
    },
    async getFacilitator(){
      if(this.session.ownerId){        
        let user = (await axios.get(`users/${this.session.ownerId}`)).data
        this.facilitator = `${user.firstName} ${user.lastName}`
      }
      else
        this.facilitator = `-`
    },
    async getTeachingAssistants(){
      let admins = (await axios.get(`sessions/${this.session.id}/admins`)).data
      this.teachingAssistants = admins.map(user => `${user.firstName} ${user.lastName}`)
    },
    formatProgramType(t){
      return formatProgramType(t)
    },
    async setWorkflow(status){
      try{
        this.loading = true
        this.$nprogress.start()    
        var formdata = new FormData()
        if(this.rejectReason)
          formdata.append("message", this.rejectReason)
        await axios.post(`sessions/${this.session.id}/setworkflow?status=${status}`, formdata)
        this.$emit('updated', status, this.rejectReason)
      }
      catch(err){
        this.$refs.snackbar.show(err?.response?.data ?? err)
      }
      finally{
        this.loading = false
        this.$nprogress.done()
      }
    },
  },
  mounted() {
    try{
      this.$nprogress.start()
      this.loading = true
      this.getFacilitator()
      this.getTeachingAssistants()
    }
    finally{
      this.loading = false
      this.$nprogress.done()
    }
  }
}
</script>

<style lang="scss">
.session-workflow{
  label{
    float: unset;
    color: black;
  }
  span{
    color: #777;
  }
  .spinner{
    position: relative;
    margin: 0;
    left: unset;
  }
}

</style>
