<template>
  <main class="sessions2 padded" style="padding-bottom:0;">
    <h2>Sessions</h2>

    <div class="formerror" v-if="error">{{error}}</div> 

    <div class="buttons">
      <div v-if="$store.getters.isAdmin" style="display: flex;">
        <select class="select-css" v-model="orgId" style="width: 200px;">
          <option :value=null>All organizations</option>
          <option :value=-1>No organization</option>
          <option v-for="org in organizations" v-bind:value="org.id" :key="org.id" :selected="org.id == $store.getters.organizationId">
            {{org.name}}
          </option>
        </select>
        <button class="icon forecast" title="Forecast" @click="showForecast()" :disabled="loading">
          <img src="../assets/chart-mixed.svg" style="height:28px;" />
        </button>
      </div>
      <input v-model.trim="searchTerm" type="text" style="width: 300px;" :disabled="loading" autocomplete="off" placeholder="Search..." /> 
      <button class="white" @click="addSession">Create session</button>
    </div>      
    
    <!-- <div class="scrollbox"> -->
      <VueGoodTable
      ref="datatable"
      mode="remote"
      :pagination-options="{
        enabled: true,
        perPageDropdownEnabled: false,
        perPage: 50,
      }"
      :totalRows="totalRecords"
      :columns="columns"
      :rows="sessions"      
      @on-sort-change="onSortChange"
      @on-column-filter="onColumnFilter"
      @on-page-change="onPageChange"
      styleClass="vgt-table"
      
      max-height="calc(100lvh - var(--size-header) - 150px - 70px)"
      :fixed-header="false"
      :select-options="{ 
        enabled: true,
        selectOnCheckboxOnly: true 
      }">
        <template v-slot:selected-row-actions>  
          <button class="insead autowidth right" :disabled="loading || !selectedSessions.length || (!$store.getters.isAdmin && !$store.getters.isOrgAdmin && selectedSessions.filter(s => s.ownerId != $store.getters.userId).length > 0)" :class="{disabled: loading || !selectedSessions.length}" @click="bulkDelete">Bulk delete</button>              
        </template>
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == 'actions'" class="rowActions">               
            <button class="icon" title="Open" @click="$router.push('dashboard2/' + props.row.id)" :disabled="!$store.getters.isAdmin && !$store.getters.isOrgAdmin && props.row.ownerId != $store.getters.userId">
              <img src="../assets/preview-icon.svg" />
            </button>
            <button class="icon" title="Manage workflow" @click="editRowWorkflow(props.row.id)" :disabled="!$store.getters.isAdmin && !$store.getters.isOrgAdmin && props.row.ownerId != $store.getters.userId">
              <img src="../assets/off.svg" />
            </button>
            <button class="icon" title="Manage devices" @click="manageRow(props.row.id)" :disabled="![3,4].includes(props.row.workflow) || (!$store.getters.isAdmin && !$store.getters.isOrgAdmin)">
              <img src="../assets/manage.svg" />
            </button>      
            <button class="icon" title="Delete" @click="deleteRow(props.row.id)" :disabled="!$store.getters.isAdmin && !$store.getters.isOrgAdmin && props.row.ownerId != $store.getters.userId">
              <img src="../assets/delete.svg" />
            </button>
            <button class="icon" title="Edit" @click="editRow(props.row.id)" :disabled="!$store.getters.isAdmin && !$store.getters.isOrgAdmin && props.row.ownerId != $store.getters.userId">
              <img src="../assets/edit.svg" />
            </button>
            <button class="icon" title="Duplicate" @click="copyRow(props.row.id)" :disabled="!$store.getters.isAdmin && !$store.getters.isOrgAdmin && props.row.ownerId != $store.getters.userId">
              <img src="../assets/duplicate.svg" />
            </button>
            <button class="icon" title="Activity Logs" @click="getLogs(props.row.id)" v-if="$store.getters.isAdmin">
              <img src="../assets/log-file-icon.svg" />
            </button>
          </span>
          <span v-else-if="props.column.field == 'status'">
            {{formatSessionStatus(props.row.status)}}
          </span>
          <span v-else-if="props.column.field == 'workflow'">
            {{formatWorkflowStatus(props.row.workflow)}}
          </span>
          <span v-else-if="props.column.field == 'program'">
            {{formatProgramType(props.row.program)}}
          </span>
          <span v-else-if="props.column.field == 'startDate'">
            {{ props.row.startDate ? formatDate(new Date(props.row.startDate), $store.getters.timeZone) + ` ${toTimeZone(new Date(props.row.startDate), $store.getters.timeZone).getFullYear()}` : '-' }}
          </span>
          <span v-else-if="props.column.field == 'endDate'">
            {{ props.row.endDate ? formatDate(new Date(props.row.endDate), $store.getters.timeZone) + ` ${toTimeZone(new Date(props.row.endDate), $store.getters.timeZone).getFullYear()}` : '-' }}
          </span>
          <span v-else-if="props.column.field == 'created'">
            {{ `${formatDate(new Date(props.row.created), $store.getters.timeZone)} ${toTimeZone(new Date(props.row.created), $store.getters.timeZone).getFullYear()}` }}
          </span>
          <span v-else>
            {{props.formattedRow[props.column.field]}}
          </span>
        </template>
      </VueGoodTable>
    <!-- </div> -->

    
    <ScheduleSession ref="editor" v-show="showModal"
      :headerTitle="($route.name == 'Session2Edit' ? 'Edit' : 'Create') + ' session'"
      :userList="users" 
      :organizationList="organizations"  
      @created="sessionCreated" 
      @updated="sessionUpdated"           
      @close="showModal = false; $router.push({name: 'Sessions2'}); " />
      
    <Modal2 v-if="sessionScheduledModal" @close="sessionScheduledModal = false;">      
      <div slot="body" style="width: 336px; text-align:center;">
        <img style="margin-top:74px;" src="../assets/mail.svg">
        <div>
          <div style="font-size:20px; padding:50px 0 25px 0;">Great!</div>          
          
          <div v-if="$store.getters.isAdmin || $store.getters.isOrgAdmin">
            The session was created successfully. 
            <br>
            You will be reminded a week before the session.
          </div>
          <div v-else>
            Your session has been created successfully.
            <br>
            An admin will be notified to prepare the session.
          </div>
        </div>
      </div>
      <div slot="footer">
        <button class="white right" @click="sessionScheduledModal = false;">Continue</button>
      </div>
    </Modal2>

    <Modal2 v-if="forecastModal" @close="forecastModal = false;">    
      <h3 slot="header">User &amp; Session count hourly forecast for one month ahead in UTC</h3>  
      <div slot="body" style="width: calc(100vw - 80px); height: calc(100vh - 250px); text-align:center;">
        <canvas id="sessionforecastchart"></canvas>
      </div>      
    </Modal2>

    <ManageSession v-if="sessionManageModal" :organizations="organizations" :sessionId="tempSession.id" @updated="sessionWorkflowUpdated" @close="sessionManageModal = false; $router.push({name: 'Sessions2'});" @avrisyncUpdated="avrisyncUpdated" />

    <SessionWorkflow v-if="sessionWorkflowModal" :session="tempSession" @close="sessionWorkflowModal = false;" @updated="sessionWorkflowUpdated" />

    <Snackbar ref="snackbar" /> 
  </main>
</template>

<script>
import axios from 'axios';

import { formatSessionStatus, formatWorkflowStatus, formatProgramType, formatDate, toTimeZone } from '@/utils.js'
import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table'
//import {Roles} from '@/roles.js'
import ScheduleSession from '../components/ScheduleSession.vue'
import ManageSession from '../components/ManageSession.vue'
import Snackbar from '@/components/Snackbar.vue'
import Modal2 from '../components/Modal2.vue'
import SessionWorkflow from '../components/SessionWorkflow.vue'
import Papa from 'papaparse'
import { saveAs } from 'file-saver'
import _ from 'lodash'
import Chart from 'chart.js/auto'

export default {
  name: 'Sessions2',
  data: function(){
    return {
      // sessionUserColumns: [
      //   {
      //     label: 'Email',
      //     field: 'email',
      //   },
      //   {
      //     label: 'Organization',
      //     field: 'organization.name',
      //   },
      //   {
      //     label: 'First Name',
      //     field: 'firstName',
      //   },
      //   {
      //     label: 'Last Name',
      //     field: 'lastName',
      //   },  
      //   // {
      //   //   label: 'ID',
      //   //   field: 'id',
      //   // },
      // ],
      columns: [
        {
          label: 'Organization',
          field: 'organizationName',          
          sortable: false,
        },
        {
          label: 'Id',
          field: 'id',
          //sortable: false,
          type: 'number',
        },
        {
          label: 'Sync',
          field: 'sync',
          filterOptions: {
            enabled: true,
            placeholder: 'No filter',
            filterDropdownItems: [true, false]
          },
        },
        {
          label: 'Workflow',
          field: 'workflow',
          filterOptions: {
            enabled: true,
            placeholder: 'No filter',
            filterDropdownItems: [
              {text: 'Requested', value: 0}, 
              {text: 'Rejected', value: 1}, 
              {text: 'Updated', value: 2}, 
              {text: 'Approved', value: 3}, 
              {text: 'Ready', value: 4}
            ]
            //filterFn: this.workflowFilterFn
          },
          sortable: false       
        },    
        {
          label: 'Practice',
          field: 'practice',
          filterOptions: {
            enabled: true,
            placeholder: 'No filter',
            filterDropdownItems: [true, false]
          },
          sortable: false                    
        },      
        {
          label: 'Name',
          field: 'name',
        },
        {
          label: 'Experience',
          field: 'experienceName',
          filterOptions: {
            enabled: true,
            placeholder: 'No filter'
          },
          sortable: false
        },
        {
          label: 'Start Date',
          field: 'startDate',          
        },
        {
          label: 'End Date',
          field: 'endDate',
        },
        {
          label: 'Created',
          field: 'created',
        }, 
        // {
        //   label: 'Structure',
        //   field: 'structure',
        // },
        {
          label: 'Status',
          field: 'status',
          filterOptions: {
            //styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'No filter', // placeholder for filter input
            // filterValue: 'Jane', // initial populated value for this filter
            filterDropdownItems: [
              {text: 'Not Started', value: 0}, 
              {text: 'Live', value: 1}, 
              {text: 'Paused', value: 2}, 
              {text: 'Self Paced', value: 3}, 
              {text: 'Finished', value: 4}
            ], // dropdown (with selected values) instead of text input
            //filterFn: this.statusFilterFn, //custom filter function that
            // trigger: 'enter', //only trigger on enter not on keyup 
          },
          sortable: false,
        },
        {
          label: 'Learners',
          field: 'userCount',
          type: 'number',
        },
        {
          label: 'Program',
          field: 'program',
          filterOptions: {
            enabled: true,
            placeholder: 'No filter',
            filterDropdownItems: [
              {text: 'Degree', value: 0}, 
              {text: 'Executive', value: 1}, 
              {text: 'Demo', value: 2},
            ],
            //filterFn: this.programFilterFn
          }
        },
        {
          label: 'Location',
          field: 'location',
        },
        // {
        //   label: 'OwnerId',
        //   field: 'ownerId',
        //   sortable: false,
        // },
        // {
        //   label: 'Experience',
        //   field: 'experience.name',
        //   filterOptions: {
        //     //styleClass: 'class1', // class to be added to the parent th element
        //     enabled: true, // enable filter for this column
        //     placeholder: 'Filter by Experience', // placeholder for filter input
        //     // filterValue: 'Jane', // initial populated value for this filter
        //     // filterDropdownItems: experiences.map(e => e.name), // dropdown (with selected values) instead of text input
        //     // filterFn: this.columnFilterFn, //custom filter function that
        //     // trigger: 'enter', //only trigger on enter not on keyup 
        //   },
        // },
        {
          label: 'Actions',
          field: 'actions',
          sortable: false,
        },
      ].filter(c => (this.$store.getters.isAdmin ? true : c.label != 'Organization') && (process.env.VUE_APP_ENV != 'prod' || c.label != 'Sync')),      
      sessions: [],
      experiences: [],
      organizations: [],
      loading: false,
      error: undefined,
      searchTerm: undefined,
      //userSearchTerm: undefined,
      //sessionUserSearchTerm: undefined,
      showModal: false,  
      sessionScheduledModal: false,
      sessionManageModal: false,
      sessionWorkflowModal: false,
      //showUsersModal: false,  
      //removeUsersModal: false,
      //showAdminsModal: false,  
      //removeAdminsModal: false,
      //selectedTreeItem: undefined,
      //selectedContentBlock: undefined,
      //publishContent: false,
      // tempSession: {
      //   id: undefined,
      //   name: undefined,
      //   startDate: undefined,
      //   endDate: undefined,
      //   structure: undefined,
      //   ownerId: undefined,
      //   custom: undefined,
      //   contentBlocks: [],
      //   organizationId: null
      // }, 
      forecast: [],
      forecastModal: false,
      tempSession: undefined,
      users: [],
      totalRecords: 0,
      serverParams: {
        columnFilters: {
        },
        sort: {
          field: 'id',
          type: 'desc'
        },
        page: 1, 
        perPage: 10
      },
      orgId: this.$store.getters.isAdmin ? null : (this.$store.getters.organizationId ?? -1)
    }
  },
  computed: {  
    selectedSessions(){
      return this.$refs.datatable.selectedRows;
    }
  },
  methods: {  
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },
    onSortChange(params) {
      this.updateParams({
        sort: ({
          type: params[0].type,
          field: params[0].field,
        }),
      })
      this.getSessions()
    },
    onColumnFilter(params) {
      this.updateParams(params)
      this.serverParams.page = 1
      this.getSessions()
    },
    onPageChange(params) {
      this.updateParams({page: params.currentPage});
      this.getSessions()
    },
    setExperiencesFilters(){
      let foundIndex = this.columns.findIndex((c) => {
        return c.field == 'experienceName'
      })        
      this.$set(this.columns[foundIndex].filterOptions, 'filterDropdownItems', this.$store.getters.orderedExperiences.map(e => ({value: e.id, text: e.name})))
    }, 
    toTimeZone(d, tz){ return toTimeZone(d,tz) }, 
    formatDate(d, tz){ return formatDate(d, tz) },
    formatSessionStatus(status){
      return formatSessionStatus(status)
    },    
    formatWorkflowStatus(status){
      return formatWorkflowStatus(status)
    },
    formatProgramType(type){
      return formatProgramType(type)
    },
    programFilterFn: function(data, filterString) {     
      return this.formatProgramType(data) == filterString
    },
    statusFilterFn: function(data, filterString) {     
      return this.formatSessionStatus(data) == filterString
    },
    workflowFilterFn: function(data, filterString) {     
      return this.formatWorkflowStatus(data) == filterString
    },
    async showForecast(){
      try{
        this.$nprogress.start()
        this.loading = true   
        let resp = await axios.get('sessions/forecast')
        this.forecast = resp.data //.map(x => ({h: this.toTimeZone(new Date(x.h), this.$store.getters.timeZone).toLocaleString(), userCount: x.userCount, sessionCount: x.sessionCount}))
        this.forecastModal = true

setTimeout( () => 
        new Chart(
            'sessionforecastchart',
            {
              type: 'line',              
              data: {
                labels: this.forecast.map(x => x.h),
                datasets: [
                  {
                    label: 'Sessions',
                    type: 'bar',
                    data: this.forecast.map(x => x.sessionCount),
                    yAxisID: 'y1'                                       
                  },
                  {
                    label: 'Users',
                    data: this.forecast.map(x => x.userCount),
                    borderColor: '#753fe0'
                  }                  
                ]
              },
              options:{                
                scales: {
                  x: {
                    grid: {
                      display: false
                    }
                  },
                  y: {
                    type: 'linear',
                    display: true,
                    position: 'left',
                    color: '#753fe0',
                    title: {
                      display: true,
                      text: 'Users',
                      color: '#753fe0',
                      font: {
                        size: 14                        
                      } 
                    },
                    ticks: {
                      color: '#753fe0'
                    },
                    grid: {
                      drawOnChartArea: false, // only want the grid lines for one axis to show up
                      drawTicks: false,
                      //drawBorder: false
                      borderWidth: 10,
                      borderColor: '#fff'
                    },
                  },
                  y1: {
                    type: 'linear',
                    display: true,
                    position: 'right',
                    title: {
                      display: true,
                      text: 'Sessions',
                      color: '#777',
                      font: {
                        size: 14
                      } 
                    },                    
                    grid: {                      
                      drawTicks: false,
                      //drawBorder: false
                      borderWidth: 10,
                      borderColor: '#fff',
                      color: '#f2f5f7'
                    },              
                  }
                },
                maintainAspectRatio: false,
                responsive: true,                
                plugins: {
                  legend: {
                    display: true//false
                  }                         
                }              
              }
            }
          ), 500)
        

      }
      finally{
        this.loading = false
        this.$nprogress.done()
      }
    },
    async getOrganizations(){
      let resp = await axios({ url: "organizations" })
      this.organizations = resp.data
    },    
    async bulkDelete(){
      if(confirm(`Delete the ${this.selectedSessions.length} selected sessions?`)){
        let forDeletion = this.selectedSessions.map(s => s.id)
        await axios({ url: `sessions`, data: forDeletion, method: 'DELETE' })        
        this.sessions = this.sessions.filter(s => !forDeletion.includes(s.id))
      }
    },
    async deleteRow(id){
      if(confirm(`Delete session with id ${id}?`)){
        await axios({ url: `sessions`, data: [id], method: 'DELETE' })        
        this.sessions = this.sessions.filter(s => s.id != id)
      }
    },    
    addSession(){
      this.$refs.editor.create()
      this.showModal = true
    },
    async getSessions(){    
      let url = `sessions?sortBy=${this.serverParams.sort.field}&sortMode=${this.serverParams.sort.type}&page=${this.serverParams.page}`
      if(this.orgId)
        url += `&orgId=${this.orgId}`  
      
      if(this.serverParams.columnFilters.sync)
        url += `&sync=${this.serverParams.columnFilters.sync}`
      if(this.serverParams.columnFilters.workflow)
        url += `&workflow=${this.serverParams.columnFilters.workflow}`
      if(this.serverParams.columnFilters.practice)
        url += `&practice=${this.serverParams.columnFilters.practice}`
      if(this.serverParams.columnFilters.experienceName)
        url += `&expId=${this.serverParams.columnFilters.experienceName}`
      if(this.serverParams.columnFilters.status)
        url += `&status=${this.serverParams.columnFilters.status}`
      if(this.serverParams.columnFilters.program)
        url += `&program=${this.serverParams.columnFilters.program}`
      if(this.searchTerm)
        url += `&searchTerm=${encodeURIComponent(this.searchTerm)}`
      let resp = await axios.get(url)
      this.totalRecords = resp.data.total
      this.sessions = resp.data.sessions
    },  
    async fetchUsers(){      
        let resp = await axios({ url: "users", method: "GET" }); 
        this.users = resp.data;      
    },
    async editRow(id){     
      if(!this.$route.params.id)
        this.$router.push({name: 'Session2Edit', params: {id: id}}) 
      let s = this.sessions.find(s => s.id == id)
      if(!s)
        s = (await axios.get(`sessions/${id}`)).data   // because of paging the actual session might need to be retrieved     
      let tmp = JSON.parse(JSON.stringify(s)) 
      this.$refs.editor.edit(tmp)
      this.showModal = true
    },
    async copyRow(id){           
      let tmp = JSON.parse(JSON.stringify(this.sessions.find(s => s.id == id)))          
      this.$refs.editor.create(tmp)
      this.showModal = true
    },
    async manageRow(id){     
      if(!this.$route.params.id || !this.$route.name == 'Session2Manage')
        this.$router.push({name: 'Session2Manage', params: {id: id}})             
      this.tempSession = this.sessions.find(s => s.id == id) ?? {id: parseInt(id)}  // because of paging the actual session might need to be retrieved (ManageSession component handles it based on Id)      
      this.sessionManageModal = true
    },
    async editRowWorkflow(id){  
      this.tempSession = this.sessions.find(s => s.id == id) 
      if(!this.tempSession)
        this.tempSession = (await axios.get(`sessions/${id}`)).data   // because of paging the actual session might need to be retrieved
      this.sessionWorkflowModal = true
    },
    async getLogs(id){
      let resp = await axios.get(`sessions/${id}/activity`)
      let csv = Papa.unparse(resp.data)
      let blob = new Blob([csv], {type: "text/plain;charset=utf-8"});
      saveAs(blob, `session-${id}-activity-logs${process.env.VUE_APP_ENV != 'prod' ? ('-'+process.env.VUE_APP_ENV) : ''}.csv`);
    },
    sessionUpdated(session){
      let indexOfItem = this.sessions.findIndex(s => s.id == session.id)      
      if(indexOfItem >= 0)
        this.$set(this.sessions, indexOfItem, session)
      this.showModal = false
      this.$router.push({name: 'Sessions2'}) 
      this.$refs.snackbar.show('Session updated successfully')
    },
    avrisyncUpdated(session){
      let s = this.sessions.find(s => s.id == session.id)  
      if(s)    
        this.$set(s, 'sync', session.sync)
    },
    sessionWorkflowUpdated(status, reason){
      const readyRevoked = this.tempSession.workflow == 4 && status != 4
      this.tempSession.workflow = status
      this.tempSession.workflowMessage = reason
      this.sessionWorkflowModal = false
      if(readyRevoked)
        this.$refs.snackbar.show('Session\'s Ready state changed back to Approved')
      else if(status == 4)
        this.$refs.snackbar.show('Session marked as Ready')
      else
        this.$refs.snackbar.show('Request ' + (status == 3 ? 'approved' : 'rejected'))
    },
    async sessionCreated(){
      await this.getSessions()
      this.showModal = false
      this.sessionScheduledModal = true
    },
  },  
  components: {
    VueGoodTable, 
    Modal2,
    Snackbar,
    ScheduleSession,
    ManageSession,
    SessionWorkflow
  },
  watch:{
    async $route (to, from){
      //console.log('from: ' + from.name + ' to: ' + to.name)
        if(to != from && to.name == 'Session2Manage')
          await this.manageRow(this.$route.params.id)     
        if(from.name == 'Session2Edit' && to.name == 'Sessions2')
          this.$store.state.showLoader = false   
    },
    '$store.state.experiences': function() {
      this.setExperiencesFilters()
    },
    showModal: function(){      
      document.querySelector('.schedule-session .modal2-body').scrollTop = 0      
    },
    searchTerm: function(){
      this.debouncedSearch()
    },
    orgId: async function(){
      this.serverParams.page = 1
      await this.getSessions()
    }     
  },
  async mounted() {
    try{
      this.$nprogress.start()
      this.loading = true      
      this.setExperiencesFilters()
      await this.getSessions()      
      await this.getOrganizations()
      this.fetchUsers()
      
      if(this.$route.params.id){      
        if(this.$route.name == 'Session2Edit')
          await this.editRow(this.$route.params.id)
        else if(this.$route.name == 'Session2Manage')
          await this.manageRow(this.$route.params.id)        
        //   await this.editUsers(this.$route.params.id)
        // else if(this.$route.name == 'SessionAdmins')
        //   await this.editAdmins(this.$route.params.id)
      }
    }
    finally{
      this.loading = false
      this.$nprogress.done()
      // loading of manage screen takes time, so cancel loading from that component instead
      if(this.$route.name != 'Session2Manage' && this.$route.name != 'Session2Edit')
        this.$store.state.showLoader = false
    }
  },
  created() {
    let localThis = this
    this.debouncedSearch = _.debounce(async function() {
      await localThis.getSessions()
    }, 500) 
  }
}
</script>

<style lang="scss">

main.sessions2{  
  display: block;
  position: relative;

  h2{
    margin: 0 0 10px 0;
    font-size: 20px;    
    font-weight: bold;
  }

  .buttons{
    display:flex; 
    justify-content: space-between; 
    align-items: flex-end; 
    margin-bottom: 14px;

    input{
      width: 300px;
      background-image: url(../assets/magnifier.svg);
      background-position: calc(100% - 6px) center;
      background-repeat: no-repeat;
      padding-right: 40px;
    }

    .forecast{
      height: 30px;
      border-radius: 5px; 
      background-color: #ffffff; 
      border: 1px solid $button-green0;
      margin-left: 15px;

      &:hover{
        background-color: #e5f7e4;
      }
    }
  }

  .scrollbox{
    height: calc(100lvh - var(--size-header) - 150px - 70px);
    overflow-y: auto;    
    padding-right: 10px;
    margin-right: -13px;

    .vgt-wrap{
      border-radius: 8px;
      border: solid 1px #d7d7d8;
      overflow: hidden;
    }
  }

  .vgt-wrap__footer{
    padding: unset;
    border: none;

    .footer__navigation{
      font-size: 14px;
      
      height: 35px;
      
      .footer__navigation__page-info{
        padding-top: 8px;
        color: #000;
      }
      button:first-of-type{
        position: absolute;
        right: 80px;
      }
      button:last-of-type{
        position: absolute;
        right: 0px;
      }
      button.footer__navigation__page-btn{
        height:35px;
        min-width: unset;
        padding: 0 5px;
        span{
          font-size:14px;
          font-weight: normal;
        }

        &:not(.disabled) .chevron
        {
          &.right::after{
            border-left-color: #4cad82;
          }
          &.left::after{
            border-right-color: #4cad82;
          }
        }

        &:hover{
          background: none;
          &:not(.disabled){
            color: #86c596;            
          }
          &.disabled{
            cursor: unset;
          }
        }
      }

      &.vgt-pull-right{
        float: none !important;
      }
    }
  }

  .vgt-table{
    border-radius: 8px;
    border: none;

    tr{
      cursor: unset;
    }
    td{
      padding: 5px 8px;      
      vertical-align: middle;    
    }
  }

  .rowActions{
    display: flex;
    align-items: center;

    button{
      width:16px;
      height: 16px;
      min-width: unset;
      margin: 0 3px;

      img{
        width: 20px;
        height: 20px;        
      }

      &[disabled]{
        background-color: transparent;
        opacity: 0.2;
      }
    }
  }

  .formerror{
    position: absolute;
    top: 0;
    left: 88px;
    right: 88px;
  }

}
</style>
